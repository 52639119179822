@font-face {
  font-family: "PreciousSans";
  src: url("../fonts/PreciousSans-Bold.woff2") format("woff2"),
    url("../fonts/PreciousSans-Bold.woff") format("woff"),
    url("../fonts/PreciousSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PreciousSans";
  src: url("../fonts/PreciousSans-Black.woff2") format("woff2"),
    url("../fonts/PreciousSans-Black.woff") format("woff"),
    url("../fonts/PreciousSans-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PreciousSans";
  src: url("../fonts/PreciousSans-BlackItalic.woff2") format("woff2"),
    url("../fonts/PreciousSans-BlackItalic.woff") format("woff"),
    url("../fonts/PreciousSans-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "PreciousSans";
  src: url("../fonts/PreciousSans-DemiBoldItalic.woff2") format("woff2"),
    url("../fonts/PreciousSans-DemiBoldItalic.woff") format("woff"),
    url("../fonts/PreciousSans-DemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "PreciousSans";
  src: url("../fonts/PreciousSans-DemiBold.woff2") format("woff2"),
    url("../fonts/PreciousSans-DemiBold.woff") format("woff"),
    url("../fonts/PreciousSans-DemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PreciousSans";
  src: url("../fonts/PreciousSans-BoldItalic.woff2") format("woff2"),
    url("../fonts/PreciousSans-BoldItalic.woff") format("woff"),
    url("../fonts/PreciousSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "PreciousSans";
  src: url("../fonts/PreciousSans-Book.woff2") format("woff2"),
    url("../fonts/PreciousSans-Book.woff") format("woff"),
    url("../fonts/PreciousSans-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PreciousSans";
  src: url("../fonts/PreciousSans-Light.woff2") format("woff2"),
    url("../fonts/PreciousSans-Light.woff") format("woff"),
    url("../fonts/PreciousSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PreciousSans";
  src: url("../fonts/PreciousSans-BookItalic.woff2") format("woff2"),
    url("../fonts/PreciousSans-BookItalic.woff") format("woff"),
    url("../fonts/PreciousSans-BookItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "PreciousSans";
  src: url("../fonts/PreciousSans-LightItalic.woff2") format("woff2"),
    url("../fonts/PreciousSans-LightItalic.woff") format("woff"),
    url("../fonts/PreciousSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "PreciousSans";
  src: url("../fonts/PreciousSans-Medium.woff2") format("woff2"),
    url("../fonts/PreciousSans-Medium.woff") format("woff"),
    url("../fonts/PreciousSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PreciousSans";
  src: url("../fonts/PreciousSans-MediumItalic.woff2") format("woff2"),
    url("../fonts/PreciousSans-MediumItalic.woff") format("woff"),
    url("../fonts/PreciousSans-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "PreciousSans";
  src: url("../fonts/PreciousSans-ThinItalic.woff2") format("woff2"),
    url("../fonts/PreciousSans-ThinItalic.woff") format("woff"),
    url("../fonts/PreciousSans-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "PreciousSans";
  src: url("../fonts/PreciousSans-Thin.woff2") format("woff2"),
    url("../fonts/PreciousSans-Thin.woff") format("woff"),
    url("../fonts/PreciousSans-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PreciousSans";
  src: url("../fonts/PreciousSans-UltraBlack.woff2") format("woff2"),
    url("../fonts/PreciousSans-UltraBlack.woff") format("woff"),
    url("../fonts/PreciousSans-UltraBlack.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PreciousSans";
  src: url("../fonts/PreciousSans-UltraBlackItalic.woff2") format("woff2"),
    url("../fonts/PreciousSans-UltraBlackItalic.woff") format("woff"),
    url("../fonts/PreciousSans-UltraBlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
